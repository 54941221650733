import React, { useEffect, useState, useRef } from 'react';
import http from './http';
import './SearchInput.css';

function SearchInput(props) {
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const requestData = {
                page: 0,
                name_table: props.name
            };
            const response = await http.post(props.routerGet, requestData);
            setData(response.data);
        };

        fetchData();
    }, [props.routerGet, props.name]);

    useEffect(() => {
        console.log(props.value);

        setSearchValue(props.value);
    }, [props.value]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSearch = async (e) => {
        const value = e.target.value;

        props.setFormData((prevData) => ({
            ...prevData,
            [props.nameField]: value,
        }));
        setSearchValue(value);
        if (!value) {
            return;
        }
        const requestData = {
            name_table: props.name,
            page: 0,
            search: value,
            searchValue: value
        };
        const response = await http.post(props.routerSearch, requestData);
        setData(response.data);
        setShowDropdown(true);
    };

    const handleItemClick = (item) => {
        setSearchValue(item.name || item.book || item.username);
        props.setFormData((prevData) => ({
            ...prevData,
            [props.nameField]: item[props.value]
        }));

        setShowDropdown(false); // Hide the dropdown after selecting an item
    };
    return (
        <div className="search-input-container">
            {/* <label>{props.label}</label> */}
            <input
                id='test'
                type='text'
                name={props.nameField}
                value={searchValue}
                onChange={handleSearch}
                onFocus={() => setShowDropdown(true)}
                className="search-input"
                placeholder={props.label}
            />
            <div ref={dropdownRef} className={`dropdown-menu ${showDropdown ? 'active' : ''}`}>
                {data.map((item, index) => (
                    <div
                        key={index}
                        onMouseDown={() => handleItemClick(item)} // Use onMouseDown instead of onClick
                        className="search-item"
                    >
                        {item.name || item.book || item.username}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SearchInput;
