import { useState } from 'react';
import { toast } from 'react-toastify';
import http from '../tools/http';
const useForm = (initialState) => {

  const [formData, setFormData] = useState(initialState);

  const [formErrors, setFormErrors] = useState({});

  const done = () => toast.success("تمت العملية بنجاح!");
  const retry = (massage) => toast.error(massage||"قم بإعادة المحاولة وفي حال التكرار تواصل مع الإدارة", {
    autoClose: 3000,
  });
  
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };
  const resetForm = () => {
    const forms = document.forms;
  
    for (let form of forms) {
      const formElements = form.elements;
  
      for (let element of formElements) {
        if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA' || element.tagName === 'SELECT') {
          element.value = '';
        }
      }
    }
  
    setFormData(initialState);
    setFormErrors({});
  };
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: file,
    }));}
  const submitForm = async ( url, AfterAdd,validateForm) => {
    if (Object.keys(formErrors).length === 0 &&validateForm()) {
      const requestData = {
        ...formData
          };
      try {
       await http.post(url, requestData)
       AfterAdd(0);
        done()
      } catch (error) {
        retry()
      }
    }
    // setFormErrors({});

  };

  return { formData,setFormData, formErrors,setFormErrors, handleInputChange,handleFileChange, resetForm, submitForm,done,retry };
};

export default useForm;
