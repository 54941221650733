import React, { useState, useEffect, useRef } from 'react';
import http from '../../tools/http.js';
import UpdateList from './UpdateList';
import AlertList from './AlertList';
import AddList from './AddList';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import styles from '../style.module.css';
import MarageList from './MarageList.js';
import MergeTypeIcon from '@mui/icons-material/MergeType';

function ContentList(props) {
    const [row, setRow] = useState({});
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [isSearch, setIsSearch] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showMergeForm, setShowMergeForm] = useState(false);
    const [showAlert, setShowAlert] = useState(false); // حالة لإظهار التنبيهات

    const lastItemRef = useRef(null);
    const containerRef = useRef(null);
    let timer;
    let get = false;

    // Fetching data
    const fetchData = async (pageNum) => {
        try {
            const requestData = {
                name_table: props.name,
                page: pageNum
            };
            const response = await http.post(`/list/page`, requestData);
            if (pageNum === 0) {
                setData(response.data);
            } else {
                setData(prevData => [...prevData, ...response.data]);
            }
            setPage(pageNum + 1);
        } catch (error) {
            console.error('Error occurred while making the request:', error);
        }
    };

    useEffect(() => {
        if (!get) {
            get=true;
            fetchData(0);
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (lastItemRef.current) {
                const { top } = lastItemRef.current.getClientRects()[0];
                const { clientHeight } = containerRef.current;
                if (clientHeight + 150 >= top && !get) {
                    get = true;
                    fetchData(page);
                }
            }
        };

        const scrollElement = containerRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll);

            return () => {
                scrollElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [page]);

    // Search Handler
    const HandelSearch = () => {
        clearTimeout(timer);
        const searchValue = document.getElementsByClassName(styles.search)[0].value.trim();
        if (searchValue.trim()) {
            timer = setTimeout(async () => {
                const requestData = {
                    name_table: props.name,
                    search: searchValue
                };
                const response = await http.post('/list/search', requestData);
                setData(response.data);
                setIsSearch(true);
            }, 1000);
        } else {
            setIsSearch(false);
            fetchData(0);
        }
    };

    const handelUpdate = (e, rowData) => {
        e.preventDefault();
        setRow(rowData);
        setShowUpdateForm(true);
    };

    // Merge Handler
    const handelMarage = (e, rowData) => {
        e.preventDefault();
        setRow(rowData);
        setShowMergeForm(true);
    };

    const showForm = () => {
        setShowAddForm(true); // Show the Add form
    };

    // Handle Delete
    const handleDelete = async (e, rowData) => {
        e.preventDefault();
        setRow(rowData);
        setShowAlert(true);
};

const hideComponent = () => {
    setShowAddForm(false);
    setShowUpdateForm(false);
    setShowMergeForm(false);
    setShowAlert(false);
}


return (
    <div className={styles.component}>
        {/* Add, Update, and Merge components */}
        {showAddForm && <AddList get={fetchData} name={props.name} label={props.label} hide={() => hideComponent()} />}
        {showUpdateForm && <UpdateList data={row} get={fetchData} name={props.name} label={props.label} hide={() => hideComponent()} />}
        {showMergeForm && <MarageList data={row} get={fetchData} name={props.name} label={props.label} hide={() => hideComponent()} />}
        {showAlert && <AlertList row={row} get={fetchData} name={props.name} hide={() => hideComponent()} />}

        {/* Button or any other way to hide the component */}

        <div className={styles.headcomponent}>
            <div className={styles.firstElment}>
                <div className={styles.searchDiv} onMouseEnter={() => {
                    document.querySelector(`.${styles.search}`).classList.add(styles.searchActive);
                }} onMouseLeave={() => {
                    document.querySelector(`.${styles.search}`).classList.remove(styles.searchActive);
                }}>
                    <SearchIcon sx={{ fontSize: "40px" }} />
                    <input type='text' className={styles.search} onChange={HandelSearch} />
                </div>
            </div>
            <div className={styles.add} onClick={showForm}>إضافة</div>
        </div>

        <div className={styles.content} ref={containerRef}>
            <table>
                <thead>
                    <tr className={styles.contentdata}>
                        {data.length > 0 ? (
                            <>
                                <th>المعرف</th>
                                <th>الاسم</th>
                                <th>عمليات</th>
                            </>
                        ) : (
                            <th colSpan="2" style={{ textAlign: 'center' }}>لا يوجد بيانات</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data.map((rowData, index) => (
                        <tr className={styles.contentdata} key={index} ref={(!isSearch && index === data.length - 1) ? lastItemRef : null}>
                            {Object.values(rowData).map((value, i) => (
                                <td key={i}>{value}</td>
                            ))}
                            <td className={styles.actionList}>
                                <EditIcon
                                    sx={{ color: 'green', cursor: "pointer" }}
                                    onClick={(event) => { handelUpdate(event, rowData) }}
                                />
                                <MergeTypeIcon
                                    sx={{ color: 'red', cursor: "pointer" }}
                                    onClick={(event) => { handelMarage(event, rowData) }}
                                />
                                <DeleteIcon
                                    sx={{ color: 'red', cursor: "pointer" }}
                                    onClick={(event) => { handleDelete(event, rowData) }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        <div className={styles.footercomponent}></div>
    </div>
);
};


export default ContentList;
