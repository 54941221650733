import React, { useEffect, useState } from 'react';
import useFetchData from '../../MyHooks/useFetchData';
import Alertquotations from './AlertQuotations'
import Addquotations from './AddQuotations';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import styles from '../style.module.css';
import { Link } from 'react-router-dom';
import AuthManager from '../../tools/AuthManager';
import background from "../../image/bg.png"
function Contentquotations() {
    const user_type = AuthManager.user_type
    const [row, setRow] = useState({});
    const [showAlert, SetshowAlert] = useState(false);
    const [showAdd, SetshowAdd] = useState(false);

    const { Data, lastItemRef, containerRef, SearchRef, fetchData } = useFetchData('/quotation/page', '/quotation/search');

    const showForm = (e) => {
        e.preventDefault();
        SetshowAdd(true);
    };

    const showRecord = (event, rowData) => {
        event.stopPropagation();
        setRow(rowData);
        SetshowAlert(true);
    };
    const hideComponent = () => {
        SetshowAlert(false);
        SetshowAdd(false);
    };
    return (
        <div className={styles.component_book}>
            {user_type === 'admin' ? <>
                {showAdd && <Addquotations get={fetchData}  hide={hideComponent} />}
                {showAlert && <Alertquotations row={row} get={fetchData} hide={hideComponent} />}
            </> : <></>}
            {/* <Updatequotations data={row} get={fetchData} /> */}
            <div className={styles.headcomponent}>
                <div className={styles.firstElment}>
                    <div className={styles.searchDiv} onMouseEnter={() => {
                        document.querySelector(`.${styles.search}`).classList.add(styles.searchActive);
                    }} onMouseLeave={() => {
                        document.querySelector(`.${styles.search}`).classList.remove(styles.searchActive);
                    }}>

                        <SearchIcon sx={{ fontSize: "40px" }} />

                        <input type='text' className={styles.search} ref={SearchRef} />
                    </div>
                </div>
                {user_type === 'admin' ?

                    <div className={styles.add} onClick={showForm}> إضافة</div> : <></>}
            </div>
            <div className={styles.content_book} ref={containerRef}>
                {Data.map((rowData, index) => (

                    <div className={styles.CardQuotations} key={index} id={index} ref={index === Data.length - 1 ? lastItemRef : null}>
                        <img src={background} alt='' width={"100%"} height={"100%"} />
                        <div className={styles.CardQuotationsContent}>
                            <p>{rowData.body}</p>

                        </div>
                        <div className={styles.CardQuotationsInfo}>
                            <p>{rowData.book}</p>
                            <p>{rowData.author}</p>
                            <Link to={`/ShowBook/${rowData.book_id}`}  >استعراض</Link>

                        </div>
                        {user_type === 'admin' ? <>

                            <div className={styles.CardQuotationsAction}>
                                <DeleteIcon sx={{ color: 'red', cursor: "pointer" }} onClick={(event) => { showRecord(event, rowData, index) }} />
                            </div>
                        </> : <></>}
                    </div>
                ))}
            </div>
            <div className={styles.footercomponent}></div>
        </div>
    );
}

export default Contentquotations;
