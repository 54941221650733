import { useState, useEffect, useRef, useCallback } from 'react';
import http from '../tools/http';
import { useLocation } from 'react-router-dom';

function useFetchData(routerGet, RouterSearch, RouterGetId, type) {
    const [Data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const lastItemRef = useRef(null);
    const containerRef = useRef(null);
    const SearchRef = useRef(null);
    const isFetchingRef = useRef(false);
    const timerRef = useRef(null);
    const hasMoreData = useRef(true);
    const location = useLocation();
    let isfetching = false;

    const handleSearch = useCallback(() => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            const value = SearchRef.current.value;
            setSearchValue(value);
            setPage(0);
            hasMoreData.current = true;
            if (location.pathname === '/Books' || location.pathname === '/') {
                localStorage.setItem("searchValue", value);
            }
        }, 300);
    }, []);

    useEffect(() => {
        const searchElement = SearchRef.current;

        if (searchElement) {
            searchElement.addEventListener('input', handleSearch);
            return () => {
                searchElement.removeEventListener('input', handleSearch);
            };
        }
    }, [handleSearch]);

    useEffect(() => {
        const savedSearchValue = localStorage.getItem("searchValue");

        if (savedSearchValue && (location.pathname === '/Books' || location.pathname === '/')) {
            setSearchValue(savedSearchValue);
            SearchRef.current.value = savedSearchValue;
        } else {
            if (!isfetching) {
                fetchData(0);
                isfetching = true;
            }
        }
    }, [location]);

    useEffect(() => {
        if (searchValue === '') {
            fetchData(0);
        } else {
            search(0);
        }
    }, [searchValue]);

    useEffect(() => {
        if (Data.length === 0) {
            return;
        }
        const handleScroll = () => {
            if (Data.length === 0) {
                return;
            }
            if (isFetchingRef.current || !hasMoreData.current) return;

            const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                isFetchingRef.current = true;
                if (searchValue === '') {
                    fetchData(page);
                }
            }
        };

        const scrollElement = containerRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll);
            return () => {
                scrollElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [page, searchValue]);

    const fetchById = async (id) => {
        try {
            const requestData = { id: id };
            const response = await http.post(RouterGetId, requestData);
            return response.data;
        } catch (error) {
            console.error('Error fetching data by ID:', error.message);
            return null;
        }
    };

    const fetchData = async (pageNum) => {
        try {
            const requestData = { page: pageNum };
            const response = type === 'get'
                ? await http.get(routerGet, { params: requestData })
                : await http.post(routerGet, requestData);

            if (response.data.length > 0) {
                setData(prevData => pageNum === 0 ? response.data : [...prevData, ...response.data]);
                setPage(prevPage => prevPage + 1);
            } else {
                hasMoreData.current = false; // No more data to load
            }
            isFetchingRef.current = false;
        } catch (error) {
            console.error('Error fetching data:', error.message);
            isFetchingRef.current = false;
        }
    };

    const search = async (pageNum) => {

        try {
            const requestData = { searchValue, page: pageNum };
            const response = await http.post(RouterSearch, requestData);

            if (response.data.length > 0) {
                setData(prevData => pageNum === 0 ? response.data : [...prevData, ...response.data]);
                setPage(prevPage => prevPage + 1);
            } else {
                hasMoreData.current = false; // No more data to load
            }
            isFetchingRef.current = false;
        } catch (error) {
            console.error('Error searching data:', error.message);
            isFetchingRef.current = false;
        }
    };

    return { Data, setData, lastItemRef, containerRef, SearchRef, fetchById, search, fetchData };
}

export default useFetchData;
