import React, { useState, useEffect } from 'react';
import styles from './MainShow.module.css';
import http from '../tools/http';
import SearchIcon from '@mui/icons-material/Search';
import Card from './Card';
import useFetchData from '../MyHooks/useFetchData';
import AuthManager from '../tools/AuthManager';

function MainShow() {
  const [dataShow, setDataShow] = useState([]);
  const {
    Data: booksData,
    setData: setBooksData,
    lastItemRef: lastItemRefBooks,
    containerRef: containerRefBooks,
    SearchRef: searchRefBooks,
    fetchById: fetchByIdBooks,
  } = useFetchData('/book/page', '/book/search', '/book/fetch');



  let classFeatched = false;
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    if (!classFeatched) {
      getClasses();
      classFeatched = true;
    }
  }, []);


  const fetchSingleBookData = async (id) => {
    const newData = await fetchByIdBooks(id);
    const dataIndex = booksData.findIndex(item => item.id === id);
    if (dataIndex !== -1) {
      const updatedData = [...booksData]; // Create a copy of booksData
      updatedData[dataIndex] = newData;
      setBooksData(updatedData);
    }
  };

  const getClasses = async () => {
    try {
      const response = await http.get('/book-classifications/all');
      setClasses(response.data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const selectClass = (e) => {
    e.preventDefault();
    const selectedElement = document.querySelector(`.${styles.select}`);
    if (selectedElement) {
      selectedElement.classList.remove(`${styles.select}`);
    }
    e.target.classList.add(`${styles.select}`);
  };

  const handleClasses = async (className) => {
    const requestData = { classes: className };
    const response = await http.post('book/classe', requestData);
    setDataShow(response.data);
  };

  return (
    <div className={styles.container_main}>
      <div className={styles.classes}>
        <div onClick={() => setDataShow([])} className={styles.classe}>
          <div onClick={(e) => selectClass(e)}>الكل</div>
        </div>
        {classes.map((classData, index) => (
          <div key={index} className={styles.classe}>
            <div onClick={(e) => { selectClass(e); handleClasses(classData.name); }}>
              {classData.name}
            </div>
          </div>
        ))}
      </div>

      <div className={styles.book}>
        <div
          className={styles.headcomponent}
          onMouseEnter={() => searchRefBooks.current.classList.add(styles.searchActive)}
          onMouseLeave={() => searchRefBooks.current.classList.remove(styles.searchActive)}
        >
          <div className={styles.searchDiv}>
            <SearchIcon sx={{ fontSize: "40px" }} />
            <input
              type='text'
              className={styles.search}
              ref={searchRefBooks}
            />
          </div>
        </div>

        <div className={styles.content_book1} ref={containerRefBooks}>
          {dataShow.length === 0 && booksData.map((book, index) => (
            <div key={index} ref={(index === booksData.length - 1) ? lastItemRefBooks : null}>
              <Card
                rowData={book}
                classes={book.book_classifications}
                fetchDatasingle={fetchSingleBookData}
                image={book.id}
              />
            </div>
          ))}
          {dataShow.length > 0 && dataShow.map((book, index) => (
            <div key={index} >
              <Card
                rowData={book}
                classes={book.book_classifications}
                fetchDatasingle={fetchSingleBookData}
                image={book.id}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MainShow;
