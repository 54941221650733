
import React from 'react';
import useDeleteData from '../../MyHooks/useDeleteData';
import styles from '../style.module.css';

function AlertUser_comments(props) {
    var initialState = {
        id: props.row.id,
        name_table: props.name
    }
    const { handleDelete } = useDeleteData('/list', initialState, props.get);

    const HandelDelete = (e) => {
        e.preventDefault();
        handleDelete();
        isHiddenRecord();
    };

    const isHiddenRecord = () => {
        props.hide()

    };

    return (
        <div className={styles.showRecord}>
            <h2>هل أنت متأكد؟</h2>
            <div className={styles.showRecordTools}>
                <a href="#" onClick={(event) => { HandelDelete(event) }}>حذف</a>
                <a onClick={isHiddenRecord}>إلغاء</a>
            </div>
        </div>
    );
}

export default AlertUser_comments;
