import React, { useEffect, useState } from 'react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Container from '@mui/material/Container';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import http, { baseURL } from '../tools/http.js';
import { useNavigate } from 'react-router-dom';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import AuthManager from '../tools/AuthManager.js';
import { LazyLoadImage } from "react-lazy-load-image-component";
import CircularProgress from '@mui/material/CircularProgress'; // لاستيراد دائرة التحميل

export default function RecipeReviewCard(props) {
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState(null); // متغير الحالة لحفظ رابط الصورة
    const [loading, setLoading] = useState(false); // حالة التحميل للتنزيل

    const handelDownload = async (e, rowData) => {
        e.stopPropagation();
        setLoading(true); // بدء التحميل عند النقر
        const requestData = { id: rowData.id };
        try {
            const response = await http.post('/book/download', requestData, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${rowData.name}.pdf`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading book: ', error);
        } finally {
            setLoading(false); // إيقاف التحميل بعد اكتمال العملية
        }
    };

    // إضافة الكتاب إلى المفضلة
    const handelfavorite = async (e, rowData) => {
        e.stopPropagation(); // منع الانتشار
        props.fetchDatasingle(rowData.id);
        const requestData = {
            user_id: AuthManager.id,
            book_id: rowData.id,
            date_added: new Date(),
        };
        try {
            await http.post('/user-favorite', requestData);
            props.fetchDatasingle(rowData.id); 
        } catch (error) {
            console.error('Error adding to favorites: ', error);
        }
    };

    // التعامل مع النقر على البطاقة
    const handleCardClick = (e) => {
        e.stopPropagation(); // منع انتشار الحدث
        navigate(`/showbook/${props.rowData.id}`); // الانتقال إلى صفحة الكتاب
    };

    // استخدام useEffect لتحميل الصورة عند تحميل المكون
    useEffect(() => {
        const imageUrl = `https://backlib.sham-dev.com/images/${props.rowData.id}`;
        fetch(imageUrl)
            .then(response => {
                const contentType = response.headers.get('Content-Type');
                if (contentType && contentType.includes('image')) {
                    return response.blob();
                } else {
                    throw new Error('Not an image');
                }
            })
            .then(blob => {
                const objectUrl = URL.createObjectURL(blob);
                setImageUrl(objectUrl); // تعيين رابط الصورة إلى state
            })
            .catch(error => {
                console.error('Error loading image: ', error);
                setImageUrl(null); // تعيين الصورة إلى null في حالة حدوث خطأ
            });
    }, [props.rowData.id]); // إعادة تنفيذ effect عند تغيير معرّف الكتاب

    return (
        <div className='card'>
            {/* إذا تم تحميل الصورة بنجاح، نعرضها */}
            {imageUrl ? (
                <LazyLoadImage
                    src={imageUrl}
                    alt={props.rowData.name}
                    onClick={handleCardClick}
                />
            ) : (
                <div>Loading image...</div> 
            )}

            <div className='dataCard'>
                <p>{props.rowData.name}</p>
                <Container fixed sx={{ height: '10vh', width: "100%", display: 'flex', position: 'relative'}}>
                    <CardActions disableSpacing>
                        {/* إضافة الكتاب إلى المفضلة */}
                        <IconButton
                            aria-label="add to favorites"
                            style={{ color: props.rowData.is_favorite ? 'red' : 'rgb(182 179 179)' }}
                            onClick={(event) => handelfavorite(event, props.rowData)}
                        >
                            <FavoriteIcon />
                        </IconButton>
                        {/* معاينة الكتاب */}
                        <IconButton
                            aria-label="view"
                            onClick={() => navigate(`/showbook/${props.rowData.id}`)}
                        >
                            <WysiwygIcon />
                        </IconButton>
                        {/* تحميل الكتاب إذا كان يحتوي على رابط تحميل */}
                        {props.rowData.has_download && (
                            <>
                                <IconButton
                                    aria-label="download"
                                    onClick={(e) => handelDownload(e, props.rowData)}
                                    disabled={loading} // تعطيل الزر أثناء التنزيل
                                >
                                    {loading ? (
                                        <CircularProgress size={24} /> // عرض مؤشر التحميل أثناء التنزيل
                                    ) : (
                                        <DownloadIcon />
                                    )}
                                </IconButton>
                                <IconButton
                                    aria-label="complate read"
                                    onClick={() => navigate(`/complate_read/${props.rowData.id}`)}
                                >
                                    <ChromeReaderModeIcon />
                                </IconButton>
                            </>
                        )}
                    </CardActions>

                    {props.update || props.delete ? (
                        <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            sx={{ position: 'absolute', bottom: -120, right: 15, transform: 'translate(0, -70%)' }}
                            icon={<AutoFixHighIcon />}
                            direction="up"
                        >
                            <SpeedDialAction
                                icon={<EditIcon onClick={props.update} />}
                                tooltipTitle="تعديل"
                            />
                            <SpeedDialAction
                                icon={<DeleteIcon onClick={props.delete} />}
                                tooltipTitle="حذف"
                            />
                        </SpeedDial>
                    ) : null}
                </Container>
            </div>
        </div>
    );
}
