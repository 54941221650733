import http from '../tools/http';
import { toast } from 'react-toastify';

function useDeleteData(RouterDelete, initialState, get) {
    const done = () => toast.success("تمت العملية بنجاح!");
    const retry = (error) => toast.error(error.message || "قم بإعادة المحاولة وفي حال التكرار تواصل مع الإدارة", {
        autoClose: 3000,
    });
    const handleDelete = async () => {
        try {
            console.log(initialState);
            const deleteData = {
                id: initialState.id
            }

            await http.delete(RouterDelete, {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(deleteData)
            }).then(() => {
                done()
                get(0)
            })
        } catch (error) {
            retry(error)
            console.error('حدث خطأ أثناء الحذف:', error);
        }
    };

    return { handleDelete };
}

export default useDeleteData;
