// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SearchInput.css */
.search-input-container {
    margin: auto;
    font-family: Arial, sans-serif;
    position: relative;
    width: 100%;
}

.search-input {
    padding: 5px;
    font-size: 16px;
    margin: auto;
    width: 100%;
    outline: none;
    background-color: #d3d3d3;
    border: none;
    border-bottom: 1px solid #7b7b7b;
    height: 40px;
    padding: 0px 12px 0px 12px;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    display: none;
    max-height: 350px;
    overflow-y: hidden;
    z-index: 1000;
    background-color: rgb(199, 199, 199);

}

.dropdown-menu.active {
    display: block;
}

.search-item {
    cursor: pointer;
    margin-bottom: 5px;
    color: var(--boxcolor);
    padding: 2px 10px;
    border-bottom: 1px solid var(--boxcolor);
}`, "",{"version":3,"sources":["webpack://./src/tools/SearchInput.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,YAAY;IACZ,8BAA8B;IAC9B,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,gCAAgC;IAChC,YAAY;IACZ,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,oCAAoC;;AAExC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;IACjB,wCAAwC;AAC5C","sourcesContent":["/* SearchInput.css */\n.search-input-container {\n    margin: auto;\n    font-family: Arial, sans-serif;\n    position: relative;\n    width: 100%;\n}\n\n.search-input {\n    padding: 5px;\n    font-size: 16px;\n    margin: auto;\n    width: 100%;\n    outline: none;\n    background-color: #d3d3d3;\n    border: none;\n    border-bottom: 1px solid #7b7b7b;\n    height: 40px;\n    padding: 0px 12px 0px 12px;\n    cursor: pointer;\n}\n\n.dropdown-menu {\n    position: absolute;\n    top: 100%;\n    left: 0px;\n    width: 100%;\n    display: none;\n    max-height: 350px;\n    overflow-y: hidden;\n    z-index: 1000;\n    background-color: rgb(199, 199, 199);\n\n}\n\n.dropdown-menu.active {\n    display: block;\n}\n\n.search-item {\n    cursor: pointer;\n    margin-bottom: 5px;\n    color: var(--boxcolor);\n    padding: 2px 10px;\n    border-bottom: 1px solid var(--boxcolor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
